import React from 'react'

const Home = React.lazy(() => import('./containers/Home'))

const routes = [{ path: '/', component: Home }]

export default routes.map(route => {
	return {
		...route,
		Component: route.component
	}
})
