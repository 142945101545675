import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import routes from './routes'
import './app.css'

function App() {
	return (
		<Router>
			<Routes>
				{routes.map(({ path, Component, guard }, index) => (
					<Route key={`route_${index}`} path={path} element={<Component />} />
				))}
			</Routes>
		</Router>
	)
}

export default App
